<!-- 基础数据统计 -->
<template>
	<div class="basicDataClass">
		<div class="title">基础数据统计</div>
		<div class="noDataClass w100" v-if="showNoData">
			<img src="@/assets/imgs/noData.png" alt="" />
		</div>
		<div
			v-show="!showNoData"
			id="myCharts"
			style="height: 254px"
			:style="{ width: chartWidth + 'px' }"
			v-loading="loading"
			element-loading-text="加载中"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.8)"
		></div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import * as echarts from 'echarts';
import { slider } from '../common';
import { random } from '@/utils';
export default {
	data() {
		return {
			myCharts: null,
			showNoData: false,
			loading: false,
		};
	},
	computed: {
		...mapState('mapData', ['addressInfo', 'mapDataArr']),
		chartWidth() {
			this.$nextTick(() => {
				return document.getElementById('myCharts').offsetWidth;
			});
		},
	},
	watch: {
		addressInfo: {
			handler(val) {
				this.init();
			},
			deep: true,
		},
		mapDataArr: {
			handler(val) {
				if (val.length === 0) {
					this.showNoData = true;
					return;
				}
				this.init();
			},
			deep: true,
		},
	},
	mounted() {
		this.init();
		window.addEventListener('resize', this.resizeHandler, false);
	},
	methods: {
		init() {
			// this.loading = true;
			this.myCharts = echarts.init(document.getElementById('myCharts'));
			// let arrData = [];
			// let regionUrl =
			// 	this.addressInfo.cityCode === 100000 || !this.addressInfo.cityCode
			// 		? this.api.getBaseData + '.json'
			// 		: this.api.getBaseData + `/${this.addressInfo.cityCode}.json`;
			// let res = await this.$http.get(regionUrl);
			// if (res.data && res.data.success) {
			// let baseTotalDataMap = res.data.collection[0].baseTotalDataMap || [];
			let arrData = this.mapDataArr.map((d) => {
				return { regionName: d.regionName, medicalOrganizations: random(0, 50), medicalWorkers: random(0, 100), people: random(0, 200) };
			});
			// this.loading = false;
			this.myCharts.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
				},
				legend: {
					data: ['机构', '医师', '群众'],
					align: 'left',
					right: 10,
					top: 18,
					textStyle: {
						color: '#fff',
					},
					itemWidth: 10,
					itemHeight: 10,
					itemGap: 35,
				},
				grid: {
					left: 25,
					right: 16,
					bottom: 16,
					top: 48,
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: arrData.map((item) => item.regionName),
						axisLine: {
							show: true,
							lineStyle: {
								color: '#063374',
								width: 1,
								type: 'solid',
							},
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							interval: 0,
							show: true,
							textStyle: {
								color: '#fff',
							},
							formatter: function (value) {
								return echarts.format.truncateText(value, 70, '…');
							},
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '(数量)',
						nameTextStyle: {
							color: '#fff',
							align: 'right',
						},
						axisLabel: {
							formatter: '{value}',
							color: '#fff',
						},
						axisTick: {
							show: false,
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#00c7ff',
								width: 1,
								type: 'solid',
							},
						},
						splitLine: {
							lineStyle: {
								color: '#063374',
							},
						},
					},
				],
				series: [
					{
						name: `机构`,
						type: 'bar',
						data: arrData.map((v) => v.medicalOrganizations),
						barWidth: 6, //柱子宽度
						barGap: 1, //柱子之间间距
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: '#02ADC0',
									},
									{
										offset: 1,
										color: '#50AD8C',
									},
								]),
								opacity: 1,
							},
						},
					},
					{
						name: `医师`,
						type: 'bar',
						data: arrData.map((v) => v.medicalWorkers),
						barWidth: 6, //柱子宽度
						barGap: 1, //柱子之间间距
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: '#D5F79C',
									},
									{
										offset: 1,
										color: '#5AD7A1',
									},
								]),
								opacity: 1,
							},
						},
					},
					{
						name: `群众`,
						type: 'bar',
						data: arrData.map((v) => v.people),
						barWidth: 6,
						barGap: 1,
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: '#4AAEFF',
									},
									{
										offset: 1,
										color: '#72E4F3',
									},
								]),
								opacity: 1,
							},
						},
					},
				],
				dataZoom: slider(arrData.length),
			});

			// if (arrData.length === 0) {
			// 	this.showNoData = true;
			// 	echarts.init(document.getElementById('myCharts')).clear();
			// 	return;
			// } else {
			// 	let medicalOrganizationsTotal = 0;
			// 	medicalOrganizationsTotal = arrData
			// 		.map((item) => item.medicalOrganizations)
			// 		.reduce((total, num) => {
			// 			return total + num;
			// 		});
			// 	let medicalWorkersTotal = 0;
			// 	medicalWorkersTotal = arrData
			// 		.map((item) => item.medicalWorkers)
			// 		.reduce((total, num) => {
			// 			return total + num;
			// 		});
			// 	let peopleTotal = 0;
			// 	peopleTotal = arrData
			// 		.map((item) => item.people)
			// 		.reduce((total, num) => {
			// 			return total + num;
			// 		});
			// 	if (medicalOrganizationsTotal === 0 && medicalWorkersTotal === 0 && peopleTotal === 0) {
			// 		this.showNoData = true;
			// 		return;
			// 	} else {
			// 		this.showNoData = false;
			// 		this.myCharts = echarts.init(document.getElementById('myCharts'));
			// 		this.myCharts.setOption({
			// 			tooltip: {
			// 				trigger: 'axis',
			// 				axisPointer: {
			// 					type: 'shadow',
			// 				},
			// 			},
			// 			legend: {
			// 				// data: [`机构：${medicalOrganizationsTotal}`, `医师：${medicalWorkersTotal}`, `群众：${peopleTotal}`],
			// 				data: ['机构', '医师', '群众'],
			// 				align: 'left',
			// 				// formatter: (data) => {
			// 				// 	console.log(data);

			// 				// },
			// 				right: 10,
			// 				top: 18,
			// 				textStyle: {
			// 					color: '#fff',
			// 				},
			// 				itemWidth: 10,
			// 				itemHeight: 10,
			// 				itemGap: 35,
			// 			},
			// 			grid: {
			// 				left: 25,
			// 				right: 16,
			// 				bottom: 16,
			// 				top: 48,
			// 				containLabel: true,
			// 			},
			// 			xAxis: [
			// 				{
			// 					type: 'category',
			// 					data: arrData.map((item) => item.regionName),
			// 					axisLine: {
			// 						show: true,
			// 						lineStyle: {
			// 							color: '#063374',
			// 							width: 1,
			// 							type: 'solid',
			// 						},
			// 					},
			// 					axisTick: {
			// 						show: false,
			// 					},
			// 					axisLabel: {
			// 						interval: 0,
			// 						show: true,
			// 						textStyle: {
			// 							color: '#fff',
			// 						},
			// 					},
			// 				},
			// 			],
			// 			yAxis: [
			// 				{
			// 					type: 'value',
			// 					name: '(数量)',
			// 					nameTextStyle: {
			// 						color: '#fff',
			// 						align: 'right',
			// 					},
			// 					axisLabel: {
			// 						formatter: '{value}',
			// 						color: '#fff',
			// 					},
			// 					axisTick: {
			// 						show: false,
			// 					},
			// 					axisLine: {
			// 						show: false,
			// 						lineStyle: {
			// 							color: '#00c7ff',
			// 							width: 1,
			// 							type: 'solid',
			// 						},
			// 					},
			// 					splitLine: {
			// 						lineStyle: {
			// 							color: '#063374',
			// 						},
			// 					},
			// 				},
			// 			],
			// 			series: [
			// 				{
			// 					name: `机构`,
			// 					type: 'bar',
			// 					data: arrData.map((v) => v.medicalOrganizations),
			// 					barWidth: 6, //柱子宽度
			// 					barGap: 1, //柱子之间间距
			// 					itemStyle: {
			// 						normal: {
			// 							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
			// 								{
			// 									offset: 0,
			// 									color: '#02ADC0',
			// 								},
			// 								{
			// 									offset: 1,
			// 									color: '#50AD8C',
			// 								},
			// 							]),
			// 							opacity: 1,
			// 						},
			// 					},
			// 				},
			// 				{
			// 					name: `医师`,
			// 					type: 'bar',
			// 					data: arrData.map((v) => v.medicalWorkers),
			// 					barWidth: 6, //柱子宽度
			// 					barGap: 1, //柱子之间间距
			// 					itemStyle: {
			// 						normal: {
			// 							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
			// 								{
			// 									offset: 0,
			// 									color: '#D5F79C',
			// 								},
			// 								{
			// 									offset: 1,
			// 									color: '#5AD7A1',
			// 								},
			// 							]),
			// 							opacity: 1,
			// 						},
			// 					},
			// 				},
			// 				{
			// 					name: `群众`,
			// 					type: 'bar',
			// 					data: arrData.map((v) => v.people),
			// 					barWidth: 6,
			// 					barGap: 1,
			// 					itemStyle: {
			// 						normal: {
			// 							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
			// 								{
			// 									offset: 0,
			// 									color: '#4AAEFF',
			// 								},
			// 								{
			// 									offset: 1,
			// 									color: '#72E4F3',
			// 								},
			// 							]),
			// 							opacity: 1,
			// 						},
			// 					},
			// 				},
			// 			],
			// 			dataZoom: slider(arrData.length),
			// 		});
			// 	}
			// }
			// }
		},
		resizeHandler() {
			this.myCharts.resize();
		},
	},
	destroyed() {
		window.removeEventListener('resize', this.resizeHandler, false);
	},
};
</script>

<style lang="scss" scoped>
.basicDataClass {
	border: 2px solid #264471;
	box-sizing: border-box;
	.title {
		background: url('../../../assets/imgs/titleBg.png') no-repeat center;
		background-size: 100% 100%;
		line-height: 32px;
		padding-left: 16px;
		font-size: 16px;
	}
	#myCharts {
		background: linear-gradient(to bottom, #0a2a57, #092448);
	}
	.noDataClass {
		height: 254px;
		display: flex;
		justify-content: center;
		align-items: center;
		// img {
		// 	width: 100%;
		// }
	}
}
</style>