<!-- 事件统计 -->
<template>
	<div class="eventStatic">
		<div class="title">事件统计</div>
		<div class="content">
			<div class="leftEvent">
				<p class="leftEvent-title">
					<countTo :startVal="0" :endVal="random(1000, 2000)" :duration="1000"></countTo>
				</p>
				<p class="leftEvent-subTitle">公共事件总数</p>
			</div>
			<div class="rightEvent">
				<p class="rightEvent-title"><countTo :startVal="0" :endVal="random(1000, 2000)" :duration="1000"></countTo></p>
				<p class="rightEvent-subTitle">突发公共事件总数</p>
			</div>
		</div>
	</div>
</template>

<script>
import countTo from 'vue-count-to';
import { random } from '@/utils';
export default {
	components: {
		countTo,
	},
	methods: {
		random,
	},
};
</script>

<style lang="scss" scoped>
.eventStatic {
	border: 2px solid #264471;
	box-sizing: border-box;
  background: rgba(5, 41, 92, .6);

	.title {
		background: url('../../../assets/imgs/titleBg.png') no-repeat center;
		background-size: 100% 100%;
		line-height: 32px;
		padding-left: 16px;
		font-size: 16px;
	}
	.content {
		background: url('../../../assets/imgs/eventStatic.png') no-repeat center;
		background-size: 100% 100%;
		display: flex;
		justify-content: space-between;
		margin: 10px 16px 16px;
		div {
			width: calc(50% - 15px);
			text-align: center;
			margin-bottom: 30px;
			padding: 30px 0;
			p {
				font-size: 16px;
				color: #fff;
				line-height: 16px;
				height: 16px;
			}
			p:first-child {
				font-size: 27px;
				margin-bottom: 10px;
				line-height: 24px;
				height: 24px;
			}
		}
		.leftEvent {
			background: url('../../../assets/imgs/blueBg.png') no-repeat center;
			background-size: 100% 100%;
			&-title {
				color: #00f0ff;
			}
		}
		.rightEvent {
			background: url('../../../assets/imgs/yellowBg.png') no-repeat center;
			background-size: 100% 100%;
			&-title {
				color: #ff9c66;
			}
		}
	}
}
</style>