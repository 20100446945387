<template>
	<div>
		<!-- <div class="nullData" v-if="showNoData" :style="{ height: chartHeight }">
			<img src="@/assets/imgs/noData.png" alt="" />
		</div> -->
		<div
			id="patrolCoverage"
			style="width: 100%"
			:style="{ height: chartHeight }"
			v-loading="loading"
			element-loading-text="加载中"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.8)"
		></div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import * as echarts from 'echarts';
import { random } from '@/utils';
export default {
	data() {
		return {
			showNoData: false,
			loading: false,
			myCharts: null,
		};
	},
	computed: {
		...mapState('mapData', ['addressInfo', 'mapDataArr']),
		chartHeight() {
			return document.body.clientHeight - 788 > 365 ? document.body.clientHeight - 788 + 'px' : 365 + 'px';
		},
	},
	props: ['checkVal'],
	watch: {
		checkVal: {
			handler(val) {
				this.getPatrolCoverage();
			},
			deep: true,
		},
		// mapDataArr: {
		// 	handler(val) {
		// 		this.getPatrolCoverage();
		// 	},
		// 	deep: true,
		// },
	},
	mounted() {
		// window.addEventListener('resize', this.resizeHandler, false);
	},
	methods: {
		async getPatrolCoverage() {
			// this.loading = true;
			let addressData = this.mapDataArr.map((v) => v.regionName);
			// let patrolData = this.mapDataArr.map((v) => {
			// 	return random(0, 100);
			// });
			// let defaultData = this.mapDataArr.map((v) => {
			// 	return 100;
			// });
			let patrolData = [];
			let defaultData = [];
			let res = await this.$http.get(this.api['RegionTourIndexes#load'].href, { params: { regionCode: this.addressInfo.regionCode } });
			if (res.data && res.data.success) {
				this.loading = false;
				let collection = res.data.collection || [];
				addressData = collection.map((item) => item.regionName);
				patrolData = collection.map((item) => {
					if (this.checkVal == '巡诊覆盖率') {
						return (item.circuitDiagnosisRegions / item.totalRegions).toFixed(2) * 1;
					} else {
						return (item.residencyRegions / item.totalRegions).toFixed(2) * 1;
					}
				});
				this.showNoData = addressData.length === 0 && patrolData.length === 0 ? true : false;
				if (this.showNoData) return;
				defaultData = collection.map((item) => {
					return 100;
				});
			}
			this.myCharts = echarts.init(document.getElementById('patrolCoverage'));
			this.myCharts.setOption({
				grid: {
					left: 10,
					right: 10,
					bottom: 10,
					top: 10,
					containLabel: true,
				},
				tooltip: {
					show: false,
				},
				backgroundColor: 'rgb(20,28,52)',
				xAxis: {
					show: false,
					type: 'value',
				},
				yAxis: [
					{
						type: 'category',
						inverse: true,
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								marginTop: 10,
							},
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLine: {
							show: false,
						},
						data: addressData,
					},
					{
						type: 'category',
						inverse: true,
						axisTick: 'none',
						axisLine: 'none',
						show: true,
						axisLabel: {
							textStyle: {
								color: '#ffffff',
								fontSize: '12',
							},
							formatter: function (value) {
								return value + ' %';
							},
						},
						data: patrolData,
					},
				],
				series: [
					{
						name: '完成率',
						type: 'bar',
						zlevel: 1,
						barWidth: 8, //柱子宽度
						itemStyle: {
							normal: {
								barBorderRadius: 30,
								color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
									{
										offset: 0,
										color: '#4AAEFF',
									},
									{
										offset: 1,
										color: '#72E4F3',
									},
								]),
							},
						},
						data: patrolData,
					},
					{
						name: '背景',
						type: 'bar',
						barWidth: 8,
						barGap: '-100%',
						data: defaultData,
						itemStyle: {
							normal: {
								color: '#1B375E',
								barBorderRadius: 30,
							},
						},
					},
				],
				dataZoom: [
					{
						// 设置滚动条的隐藏或显示
						show: patrolData.length > 10,
						// 设置类型
						type: 'slider',
						// 设置背景颜色
						backgroundColor: '#031227',
						// 设置选中范围的填充颜色
						fillerColor: '#203A66',
						// 设置边框颜色
						borderColor: '#031227',
						// 是否显示detail，即拖拽时候显示详细数值信息
						showDetail: false,
						// 数据窗口范围的起始数值
						startValue: 0,
						right: 0, //右边的距离
						bottom: 0, //下边的距离
						// 数据窗口范围的结束数值（一页显示多少条数据）
						endValue: 10,
						// 控制哪个轴，如果是number表示控制一个轴，
						// 如果是Array表示控制多个轴。此处控制第二根轴
						yAxisIndex: [0, 1],
						// empty：当前数据窗口外的数据，被设置为空。
						// 即不会影响其他轴的数据范围
						filterMode: 'empty',
						// 滚动条高度
						width: 6,
						// 滚动条显示位置
						height: '100%',
						// 控制手柄的尺寸
						handleSize: 0,
						// 是否锁定选择区域（或叫做数据窗口）的大小
						zoomLoxk: false,
						// 组件离容器上侧的距离
						// 如果top的值为'top', 'middle', 'bottom'，组件会根据相应的位置自动对齐
						top: 'middle',
						minValueSpan: 10, // 放大到最少几个
						maxValueSpan: 10, //  缩小到最多几个
					},
					{
						// 没有下面这块的话，只能拖动滚动条，
						// 鼠标滚轮在区域内不能控制外部滚动条
						type: 'inside',
						// 控制哪个轴，如果是number表示控制一个轴，
						// 如果是Array表示控制多个轴。此处控制第二根轴
						yAxisIndex: [0, 1],
						// 滚轮是否触发缩放
						zoomOnMouseWheel: false,
						// 鼠标移动能否触发平移
						moveOnMouseMove: true,
						// 鼠标滚轮能否触发平移
						moveOnMouseWheel: true,
					},
				],
			});
		},
		// resizeHandler() {
		// 	this.myCharts.resize();
		// },
	},
	destroyed() {
		// window.removeEventListener('resize', this.resizeHandler, false);
	},
};
</script>

<style lang="scss" scoped>
</style>