<template>
	<div
		class="visitHistory"
		v-loading="visitHistoryLoading"
		element-loading-text="加载中"
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.9)"
	>
		<div>
			<div class="visitHistory-title">
				<div class="leftBox">{{ dataObj.discriminationCode == 'T' ? '巡诊' : '驻派' }}记录</div>
				<div class="rightBox">
					<img @click="closeCarInfo" src="@/assets/imgs/visitCloseBtn.png" alt="" />
				</div>
			</div>
			<div v-if="historyList.length == 0" class="nullData">暂无数据</div>
			<div class="visitHistory-context" v-else>
				<div class="historyList" v-for="item in historyList" :key="item.id">
					<div class="historyList-tag">
						<span class="point"></span>
						<span class="line"></span>
					</div>
					<div class="historyList-item">
						<p class="historyList-item-time">{{ item.createTime || '-' }}</p>
						<p class="historyList-item-address">{{ item.homeAddress || '无地址信息' }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			visitHistoryLoading: false,
			historyList: [],
		};
	},
	props: {
		dataObj: {
			type: Object,
			default: () => ({}),
		},
		doctorInfo: {
			type: Object,
			default: () => ({}),
		},
	},
	watch: {
		dataObj: {
			handler(val) {
				this.getData();
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		closeCarInfo() {
			this.$emit('closeHistoryInfo');
		},
		// 获取数据
		getData() {
			this.visitHistoryLoading = true;
			let params = {
				planId: this.dataObj.id,
				touristId: this.doctorInfo.id,
				discriminationCode: this.dataObj.discriminationCode,
			};
			this.$http
				.get(this.api['TourWorkLogs#index'].href, { params })
				.then((res) => {
					if (res.data && res.data.success) {
						this.visitHistoryLoading = false;
						const { collection } = res.data;
						this.historyList = collection;
						console.log(this.historyList, 'this.historyList');
					}
				})
				.catch((err) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.visitHistory {
	width: 325px;
	height: 350px;
	background: url('../../../../assets/imgs/visitGroupBg.png') no-repeat center;
	background-size: 100% 100%;
	position: relative;
	margin-left: 10px;
	&-title {
		height: 52px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 25px;
		background: url('../../../../assets/imgs/visitHistoryTitleBg.png') no-repeat center;
		background-size: 100% 100%;
		color: #fff;
		.leftBox {
			margin-top: 6px;
			font-size: 22px;
			font-family: PangMenZhengDao;
			line-height: 22px;
			height: 25px;
			background: url('../../../../assets/imgs/线.png') no-repeat bottom;
			background-size: 100% 5px;
		}
		.rightBox {
			img {
				width: 28px;
				height: 28px;
				cursor: pointer;
				margin-top: 6px;
			}
		}
	}
	&-context {
		margin-top: 10px;
		padding: 14px 24px;
		height: 286px;
		overflow: auto;
		.historyList {
			color: #fff;
			display: flex;
			margin-bottom: 10px;
			&-tag {
				position: relative;
				span {
					display: inline-block;
				}
				.point {
					position: absolute;
					width: 6px;
					height: 6px;
					background: #1feaf6;
					border-radius: 50%;
					top: 4px;
					left: 0;
				}
				.line {
					width: 2px;
					height: calc(100% - 4px);
					background: linear-gradient(180deg, rgba(31, 234, 246, 0.15), #0d2b53 100%);
					position: absolute;
					top: 8px;
					left: 2.5px;
				}
			}
			&-item {
				padding-bottom: 10px;
				margin-left: 20px;
				&-time {
					font-size: 13px;
					line-height: 13px;
					height: 13px;
				}
				&-address {
					margin-top: 6px;
					color: #c4e1fb;
					font-size: 12px;
					line-height: 16px !important;
				}
			}
		}
	}
	.nullData {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		font-size: 16px;
	}
}
</style>