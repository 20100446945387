var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"personalClass"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v(" "+_vm._s((_vm.addressInfo.cityCode + '').length == 12 ? '电子健康档案统计' : '人才培养培训统计')+" ")]),((_vm.addressInfo.cityCode + '').length != 12)?_c('div',{staticClass:"checkRadioClass"},[_c('el-radio',{attrs:{"label":"1"},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v("人才数据")]),_c('el-radio',{attrs:{"label":"2"},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v("培训数据")])],1):_vm._e()]),_c('div',{staticClass:"tableClass"},[((_vm.addressInfo.cityCode + '').length != 12)?_c('div',[(_vm.radio == 1)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.personalTableData,"height":_vm.tableHeight}},[_c('el-table-column',{attrs:{"prop":"regionName","label":!_vm.addressInfo.regionCode
							? '省份'
							: (_vm.addressInfo.regionCode + '').length == 2
							? '市'
							: (_vm.addressInfo.regionCode + '').length == 4
							? '区县'
							: (_vm.addressInfo.regionCode + '').length == 6
							? '乡镇'
							: (_vm.addressInfo.regionCode + '').length == 9
							? '乡村'
							: '',"show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"全科医生","min-width":"72","show-overflow-tooltip":"","prop":"doctor1"}}),_c('el-table-column',{attrs:{"label":"临床医师","min-width":"72","show-overflow-tooltip":"","prop":"doctor2"}}),_c('el-table-column',{attrs:{"label":"中医医师","min-width":"72","show-overflow-tooltip":"","prop":"doctor3"}}),_c('el-table-column',{attrs:{"label":"护士","min-width":"72","show-overflow-tooltip":"","prop":"doctor4"}})],1):_c('div',[_c('el-table',{attrs:{"data":_vm.traningTableData,"height":_vm.tableHeight}},[_c('el-table-column',{attrs:{"prop":"regionName","label":!_vm.addressInfo.regionCode
								? '省份'
								: (_vm.addressInfo.regionCode + '').length == 2
								? '市'
								: (_vm.addressInfo.regionCode + '').length == 4
								? '区县'
								: (_vm.addressInfo.regionCode + '').length == 6
								? '乡镇'
								: (_vm.addressInfo.regionCode + '').length == 9
								? '乡村'
								: ''}}),_c('el-table-column',{attrs:{"prop":"trainingTypeDictionaryItemName","label":"培训类型"}}),_c('el-table-column',{attrs:{"prop":"trainingTimes","label":"培训次数"}}),_c('el-table-column',{attrs:{"prop":"trainingPersonTimes","label":"培训人数"}})],1)],1)],1):_c('div',[_c('el-table',{attrs:{"data":_vm.healthTotalTable,"height":_vm.tableHeight}},[_c('el-table-column',{attrs:{"prop":"name","label":"姓名"}}),_c('el-table-column',{attrs:{"label":"性别"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s((scope.row.genderDictionaryItem && scope.row.genderDictionaryItem.title) || '-')+" ")]}}])}),_c('el-table-column',{staticClass:"healthAge",attrs:{"label":"年龄"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s((scope.row.birthDate && _vm.getAge(scope.row.birthDate)) || '-')+" ")]}}])}),_c('el-table-column',{staticClass:"healthText",attrs:{"label":"创建时间","prop":"createTime","width":"140"}})],1)],1)]),(_vm.radio != 1 && (_vm.addressInfo.cityCode + '').length != 12)?_c('div',{staticClass:"checkTabsClass"},_vm._l((_vm.tabDatas),function(item){return _c('div',{key:item.id,staticClass:"tabs",class:{ activeClass: _vm.checkTabs == item.id },on:{"click":function($event){return _vm.changeTabs(item)}}},[_c('span',[_vm._v(_vm._s(item.title))])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }