<!-- 服务和活动统计 -->
<template>
	<div class="serviceAndActivity">
		<div class="title">服务和活动统计</div>
		<div class="serviceAndActivity-content">
			<img src="@/assets/imgs/computer.png" alt="" />
			<div class="dataContent">
				<div class="datasProgress" v-for="item in datas" :key="item.value">
					<p class="progressTitle clearfix">
						<span class="float-l progressTitle-title">{{ item.name }}</span>
						<span class="float-r progressTitle-value">{{ item.value }}</span>
					</p>
					<div :id="item.ids" style="width: 100%; height: 20px"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
import { random } from '@/utils';
export default {
	data() {
		return {
			myCharts1: null,
			myCharts2: null,
			myCharts3: null,
			datas: [
				{
					name: '健康宣教',
					value: random(100, 10000),
					ids: 'teaching',
				},
				{
					name: '健康档案',
					value: random(300, 8000),
					ids: 'bookText',
				},
				{
					name: '健康活动',
					value: random(50, 1000),
					ids: 'activity',
				},
			],
		};
	},
	mounted() {
		this.init1();
		this.init2();
		this.init3();
		window.addEventListener('resize', this.resizeHandler, false);
	},
	methods: {
		init1() {
			var value = [random(0, 100)];
			var max1 = Math.max.apply(null, value) * 1.2;
			var data1 = [];

			//data1存放背景条的数值，for循环使其数量与value一致
			for (var i = value.length - 1; i >= 0; i--) {
				data1.push(max1);
			}
			this.myCharts1 = echarts.init(document.getElementById('teaching'));
			this.myCharts1.setOption({
				grid: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
				xAxis: {
					show: false,
					type: 'value',
					boundaryGap: [0, 0],
				},
				yAxis: [
					{
						type: 'category',
						data: [''],
						axisLine: { show: false },
						axisTick: [
							{
								show: false,
							},
						],
					},
				],
				series: [
					{
						type: 'bar',
						zlevel: 1,
						itemStyle: {
							normal: {
								barBorderRadius: 4,
								color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
									{
										offset: 1,
										color: '#4AAEFF',
									},
									{
										offset: 0,
										color: '#72E4F3',
										opcity: 0.5,
									},
								]),
							},
						},
						barWidth: 8,
						data: value,
					},
					{
						//辅助1：真实数值的圆点
						name: '真实值圆点',
						type: 'scatter',
						symbolOffset: ['10%', '0'],
						symbolSize: 8,
						itemStyle: {
							borderWidth: 0,
							color: 'rgba(255,255,255,1)',
						},
						data: value,
						z: 10,
					},
					{
						//辅助2：真实数值的圆点外侧圆环
						name: '真实值圆环',
						type: 'scatter',
						symbolOffset: ['10%', '0'],
						symbolSize: 15,
						itemStyle: {
							borderWidth: 1,
							borderColor: '#4397DE',
							color: '#061936',
						},
						data: value,
						z: 5,
					},
					{
						name: '背景',
						type: 'bar',
						barWidth: 8,
						barGap: '-100%',
						data: [100],
						itemStyle: {
							normal: {
								color: '#1E345D',
								barBorderRadius: 4,
							},
						},
					},
				],
			});
		},
		init2() {
			var value = [25];
			var max1 = Math.max.apply(null, value) * 1.2;
			var data1 = [];
			for (var i = value.length - 1; i >= 0; i--) {
				data1.push(max1);
			}
			this.myCharts2 = echarts.init(document.getElementById('bookText'));
			this.myCharts2.setOption({
				grid: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
				xAxis: {
					show: false,
					type: 'value',
					boundaryGap: [0, 0],
				},
				yAxis: [
					{
						type: 'category',
						data: [''],
						axisLine: { show: false },
						axisTick: [
							{
								show: false,
							},
						],
					},
				],
				series: [
					{
						type: 'bar',
						zlevel: 1,
						itemStyle: {
							normal: {
								barBorderRadius: 4,
								color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
									{
										offset: 1,
										color: '#4AAEFF',
									},
									{
										offset: 0,
										color: '#72E4F3',
										opcity: 0.5,
									},
								]),
							},
						},
						barWidth: 8,
						data: value,
					},
					{
						//辅助1：真实数值的圆点
						name: '真实值圆点',
						type: 'scatter',
						symbolOffset: ['10%', '0'],
						symbolSize: 8,
						itemStyle: {
							borderWidth: 0,
							color: 'rgba(255,255,255,1)',
						},
						data: value,
						z: 10,
					},
					{
						//辅助2：真实数值的圆点外侧圆环
						name: '真实值圆环',
						type: 'scatter',
						symbolOffset: ['10%', '0'],
						symbolSize: 15,
						itemStyle: {
							borderWidth: 1,
							borderColor: '#4397DE',
							//shadowBlur: 15,
							//shadowColor: 'rgba(101,224,255,1)',
							color: '#061936',
						},
						data: value,
						z: 5,
					},
					{
						name: '背景',
						type: 'bar',
						barWidth: 8,
						barGap: '-100%',
						data: [100],
						itemStyle: {
							normal: {
								color: '#1E345D',
								barBorderRadius: 4,
							},
						},
					},
				],
			});
		},
		init3() {
			var value = [53];
			var max1 = Math.max.apply(null, value) * 1.2;
			var data1 = [];
			for (var i = value.length - 1; i >= 0; i--) {
				data1.push(max1);
			}
			this.myCharts3 = echarts.init(document.getElementById('activity'));
			this.myCharts3.setOption({
				grid: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
				xAxis: {
					show: false,
					type: 'value',
					boundaryGap: [0, 0],
				},
				yAxis: [
					{
						type: 'category',
						data: [''],
						axisLine: { show: false },
						axisTick: [
							{
								show: false,
							},
						],
					},
				],
				series: [
					{
						type: 'bar',
						zlevel: 1,
						itemStyle: {
							normal: {
								barBorderRadius: 4,
								color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
									{
										offset: 1,
										color: '#4AAEFF',
									},
									{
										offset: 0,
										color: '#72E4F3',
										opcity: 0.5,
									},
								]),
							},
						},
						barWidth: 8,
						data: value,
					},
					{
						//辅助1：真实数值的圆点
						name: '真实值圆点',
						type: 'scatter',
						symbolOffset: ['10%', '0'],
						symbolSize: 8,
						itemStyle: {
							borderWidth: 0,
							color: 'rgba(255,255,255,1)',
						},
						data: value,
						z: 10,
					},
					{
						//辅助2：真实数值的圆点外侧圆环
						name: '真实值圆环',
						type: 'scatter',
						symbolOffset: ['10%', '0'],
						symbolSize: 15,
						itemStyle: {
							borderWidth: 1,
							borderColor: '#4397DE',
							//shadowBlur: 15,
							//shadowColor: 'rgba(101,224,255,1)',
							color: '#061936',
						},
						data: value,
						z: 5,
					},
					{
						name: '背景',
						type: 'bar',
						barWidth: 8,
						barGap: '-100%',
						data: [100],
						itemStyle: {
							normal: {
								color: '#1E345D',
								barBorderRadius: 4,
							},
						},
					},
				],
			});
		},
		resizeHandler() {
			this.myCharts1.resize();
			this.myCharts2.resize();
			this.myCharts3.resize();
		},
	},
	destroyed() {
		window.removeEventListener('resize', this.resizeHandler, false);
	},
};
</script>

<style lang="scss" scoped>
.serviceAndActivity {
	border: 2px solid #264471;
	box-sizing: border-box;
  background: rgba(5, 41, 92, .6);

	.title {
		background: url('../../../assets/imgs/titleBg.png') no-repeat center;
		background-size: 100% 100%;
		line-height: 32px;
		padding-left: 16px;
		font-size: 16px;
	}
	&-content {
		padding: 16px 20px;
		display: flex;
		img {
			width: 115px;
		}
		.dataContent {
			flex: 1;
			margin-left: 22px;
			.datasProgress {
				margin-bottom: 5px;
				&:last-child {
					margin-bottom: 0;
				}
				.progressTitle-title {
					font-size: 14px;
					color: #fff;
				}
				.progressTitle-value {
					font-size: 16px;
					color: #0bf9fe;
				}
			}
		}
	}
}
</style>